import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import FaqSection from '../components/faq/faq';
import ParticipateBayerTrial from '../components/participate-bayer-trial/pbt';
import { NavTabs } from '../components/navtabs/navtabs';
import YoutubeVideoPlayer from '../components//video-player/video-player';
import { graphql } from 'gatsby';
import { Fonts, Container, WhatAreCTPage } from '../global.css';
import { Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import { IoMdArrowDropright } from 'react-icons/io';

class WhatAreClinicalTrials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hideVideo: true,
    };
  }

  videoBtnClick = e => {
    e.preventDefault();
    this.setState({
      hideVideo: false,
    });
  };

  render() {
    let { data } = this.props;
    return (
      <Layout meta={data.whatAreClinicalTrialsEnJson.seo}>
        <Fonts>
          <WhatAreCTPage>
            <Container>
              <h2 className={'main-title'}>
                {data.whatAreClinicalTrialsEnJson.title}
              </h2>
              <div
                className={'subtitle'}
                dangerouslySetInnerHTML={{
                  __html:
                    data.whatAreClinicalTrialsEnJson.content.childMarkdownRemark
                      .html,
                }}
              />
              <Row>
                <Col>
                  <div className={'banner-container'}>
                    {this.state.hideVideo ? (
                      <div className={'banner'}>
                        <Img
                          className={'banner-left'}
                          fluid={
                            data.whatAreClinicalTrialsEnJson.bannerImg
                              .childImageSharp.fluid
                          }
                          alt={data.whatAreClinicalTrialsEnJson.bannerAlt}
                        />
                        <div className={'banner-right'}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.whatAreClinicalTrialsEnJson.bannerContent
                                  .childMarkdownRemark.html,
                            }}
                          />
                          <button
                            className={'video-btn'}
                            onClick={this.videoBtnClick}
                          >
                            {data.whatAreClinicalTrialsEnJson.videoBtn}
                            <span>
                              <IoMdArrowDropright />
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className={'banner-video'}>
                        <YoutubeVideoPlayer
                          title={data.whatAreClinicalTrialsEnJson.title}
                          width="100%"
                          height="100%"
                          code={data.whatAreClinicalTrialsEnJson.videoCodeYT}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row id="phases">
                <Col xl={6} className={'phases-text'}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.whatAreClinicalTrialsEnJson.phasesContent
                          .childMarkdownRemark.html,
                    }}
                  />
                </Col>
                <Col xl={6}>
                  <NavTabs
                    phasesObj={data.whatAreClinicalTrialsEnJson.phasesBox}
                  />
                </Col>
              </Row>
              <h2 id="faq" className={'main-title faq-title'}>
                {data.whatAreClinicalTrialsEnJson.faqsTitle}
              </h2>
              <FaqSection
                faqItems={data.whatAreClinicalTrialsEnJson.faqs}
                videoBtnText={data.whatAreClinicalTrialsEnJson.videoBtn}
              />
              <ParticipateBayerTrial />
            </Container>
          </WhatAreCTPage>
        </Fonts>
      </Layout>
    );
  }
}

WhatAreClinicalTrials.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WhatAreClinicalTrials;

export const query = graphql`
  query WhatAreClinicalTrials {
    whatAreClinicalTrialsEnJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      bannerContent {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      bannerAlt
      bannerImg {
        childImageSharp {
          fluid(maxHeight: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      videoBtn
      videoCodeYT
      phasesContent {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      phasesBox {
        title
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
      }
      faqsTitle
      faqs {
        title
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        img {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        videoCodeYT
      }
      seo {
        pageTitle
        pageDescription
      }
    }
  }
`;
