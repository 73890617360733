import styled from 'styled-components';
import Img from 'gatsby-image';

export const CollapseContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  border-bottom: solid 1px #66b512;
  .active {
    span:last-child {
      transform: rotate(180deg);
    }
  }
`;

export const CollapseBtn = styled.button`
  width: 100%;
  position: relative;
  font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 18px;
  color: #0d2a40;
  text-align: left;
  padding: 10px 0;
  padding-right: 35px;
  line-height: 1.5;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid transparent;
  &:focus {
    outline: none;
  }
  span:first-child {
    position: relative;
  }
  span:last-child {
    width: 26px;
    height: 26px;
    font-size: 18px;
    line-height: 1.5;
    color: #66b512;
    position: absolute;
    right: 0;
    top: 10px;
    background-image: url(/images/green-arrow.png);
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    span:last-child {
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }
`;

export const CollapseText = styled.div`
  padding: 20px 0;
  p {
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    font-size: 16px;
    color: #0d2a40;
    margin: 0;
    line-height: 1.5;
    text-align: left;
  }
  ul {
    margin-bottom: 16px;
    padding-left: 40px;
    line-height: 1.5;
    li {
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      color: #0d2a40;
      font-size: 16px;
      list-style-type: disc;
      line-height: 1.5;
      text-align: left;
      strong {
        font-size: 16px;
        color: #0d2a40;
        line-height: 1.5;
      }
    }
  }
`;

export const CollapseMediaContainer = styled.div`
  position: relative;
  margin-bottom: 18px;
  max-width: 432px;
  display: flex;
  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
`;

export const StyledImg = styled(Img)`
  max-height: 288px;
  div {
    display: flex;
    width: auto !important;
    height: auto;
    padding: 0 !important;
  }
  picture {
    img {
      position: relative !important;
      height: auto !important;
      width: auto !important;
    }
  }
  @media screen and (max-width: 480px) {
    picture {
      img {
        width: 100% !important;
      }
    }
  }
`;

export const VideoBtn = styled.button`
  position: absolute;
  bottom: 0;
  right: 20px;
  margin-bottom: 16px;
  background-color: #66b512;
  border: solid 1px #66b512;
  word-break: break-word;
  border-radius: 0;
  color: #ffffff;
  font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1.5;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 64px 8px 8px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  svg {
    width: 48px;
    height: calc(100% + 1px);
    background-color: #4a8c0f;
    position: absolute;
    right: -1px;
    top: -1px;
    font-size: 34px;
  }
  span {
    ::after {
      width: 14px;
      height: 100%;
      background: linear-gradient(
        to right bottom,
        transparent 49%,
        #4a8c0f 50%
      );
      // border-bottom: 47px solid #4a8c0f;
      // border-left: 14px solid transparent;
      content: ' ';
      position: absolute;
      right: 47px;
      top: 0;
      margin-top: 0;
      display: block;
    }
  }
  :hover {
    background-color: #76cd19;
    border: 1px solid #76cd19;
  }
  :focus {
    outline: none;
  }
  @media (max-width: 447px) {
    max-width: max-content;
    width: calc(100% - 40px);
  }
`;
