import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import FAQCollapse from './collapse';

const FaqSection = ({ faqItems, videoBtnText }) => {
  const [isOpen, setIsOpen] = useState(faqItems.map(() => false));
  const toggle = itemIndex => {
    setIsOpen(
      isOpen.map((value, index) => (index !== itemIndex ? false : !value))
    );
  };

  const middleOfArray = Math.floor((faqItems.length - 1) / 2);
  // Used to split the items on two columns

  return (
    <Row>
      <Col xl={6}>
        {faqItems.map((item, index) =>
          index <= middleOfArray ? (
            <FAQCollapse
              item={item}
              videoBtnText={videoBtnText}
              toggle={() => toggle(index)}
              isOpen={isOpen[index]}
            ></FAQCollapse>
          ) : null
        )}
      </Col>
      <Col xl={6}>
        {faqItems.map((item, index) =>
          index > middleOfArray ? (
            <FAQCollapse
              item={item}
              videoBtnText={videoBtnText}
              toggle={() => toggle(index)}
              isOpen={isOpen[index]}
            ></FAQCollapse>
          ) : null
        )}
      </Col>
    </Row>
  );
};

FaqSection.propTypes = {
  faqItems: PropTypes.array,
  videoBtnText: PropTypes.string,
};

export default FaqSection;
