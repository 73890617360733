import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import YoutubeVideoPlayer from '../video-player/video-player';
import { IoMdArrowDropright } from 'react-icons/io';
import {
  CollapseContainer,
  CollapseBtn,
  CollapseText,
  CollapseMediaContainer,
  StyledImg,
  VideoBtn,
} from './faq.css';

const FAQCollapse = ({ item, videoBtnText, toggle, isOpen }) => {
  const [isVideoHidden, setVideoVisible] = useState(true);
  const showVideoPlayer = () => setVideoVisible(!isVideoHidden);

  return (
    <CollapseContainer>
      <CollapseBtn
        className={isOpen === false ? '' : 'active'}
        onClick={() => toggle()}
      >
        <span>{item.title}</span>
        <span />
      </CollapseBtn>
      <Collapse isOpen={isOpen}>
        <CollapseText
          dangerouslySetInnerHTML={{
            __html: item.content.childMarkdownRemark.html,
          }}
        />
        {item.img ? (
          <div>
            {isVideoHidden ? (
              <CollapseMediaContainer>
                <StyledImg
                  fluid={item.img.childImageSharp.fluid}
                  alt={item.title}
                />
                <VideoBtn onClick={showVideoPlayer}>
                  {videoBtnText}
                  <span>
                    <IoMdArrowDropright />
                  </span>
                </VideoBtn>
              </CollapseMediaContainer>
            ) : (
              <CollapseMediaContainer>
                <YoutubeVideoPlayer
                  title={item.title}
                  width="100%"
                  height="288px"
                  code={item.videoCodeYT}
                />
              </CollapseMediaContainer>
            )}
          </div>
        ) : null}
      </Collapse>
    </CollapseContainer>
  );
};

FAQCollapse.propTypes = {
  item: PropTypes.object,
  videoBtnText: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default FAQCollapse;
