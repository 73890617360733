import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const NavTabs = ({ phasesObj }) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className={'phases-box'}>
      <Nav tabs>
        {phasesObj.map((item, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({
                active: activeTab === index + 1,
              })}
              onClick={() => {
                let tab = index + 1;
                toggle(tab);
              }}
            >
              {item.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {phasesObj.map((item, index) => (
          <TabPane key={index} tabId={index + 1}>
            <div
              className={'phase-detail fade'}
              dangerouslySetInnerHTML={{
                __html: item.content.childMarkdownRemark.html,
              }}
            />
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

NavTabs.propTypes = {
  phasesObj: PropTypes.array.isRequired,
};
